import React from 'react'
import clsx from 'clsx'

import '../styles/global.scss'
import * as styles from '../styles/pages/index.module.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExchangeAlt,
  faUsers,
  faCalendarAlt,
  faChartPie,
} from '@fortawesome/free-solid-svg-icons'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import { SEO } from '../components/SEO'
import { Divider } from '../components/Divider'
import { Header } from '../components/Header'
import { Slider } from '../components/Slider'
import { SectionTitle } from '../components/SectionTitle'
import { AboutUs } from '../components/AboutUs'
import { Statistics } from '../components/Statistics'
import { Offices } from '../components/Offices'
import { Footer } from '../components/Footer'
import { Button } from '../components/Button'
import { SuccessCases } from '../components/SuccessCases'

const Home: React.FC = () => {
  return (
    <main>
      <SEO title="Consultoria em Gestão por Indicadores Estratégicos KPIs - Valore Brasil" />
      <Header />
      <Slider />

      <section id="servicos">
        <div className="pt-3 pb-5">
          <div className="container">
            <SectionTitle
              title="Nosso Propósito"
              description="Propor soluções através do uso da Controladoria para melhorar o resultado do seu negócio, fazendo uma Gestão Compartilhada e melhorando o Processo Decisório, ligado à Meritocracia e Planos de Ação consistentes."
            />
          </div>
          <div className={clsx([`container`, styles.containerLarge])}>
            <div className="row mt-3">
              <div
                className={clsx([`col-lg-3 mb-5 mb-lg-0`, styles.stepsItem])}
              >
                <div className={styles.stepCircle}>
                  <FontAwesomeIcon icon={faChartPie} />
                </div>
                <b>Criação dos Indicadores (KPIs)</b>
                <p>
                  Nesta etapa, elaboramos um relatório histórico com os
                  principais indicadores que direcionam o resultado do negócio,
                  nos níveis: estratégico, tático e operacional.
                </p>
              </div>

              <div
                className={clsx([`col-lg-3 mb-5 mb-lg-0`, styles.stepsItem])}
              >
                <div className={styles.stepCircle}>
                  <FontAwesomeIcon icon={faUsers} />
                </div>
                <b>Plano de Metas</b>
                <p>
                  Iremos estabelecer um Plano de Metas com Meritocracias ligadas
                  aos donos dos KPIs. Este plano motiva e atrai
                  responsabilidades para a equipe. É boa prática de mercado a
                  divisão de parte do ganho excedente.
                </p>
              </div>

              <div
                className={clsx([`col-lg-3 mb-5 mb-lg-0`, styles.stepsItem])}
              >
                <div className={styles.stepCircle}>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </div>
                <b>Relatórios Mensais</b>
                <p>
                  Iremos analisar através dos relatórios mensais o resultado
                  previsto vs. realizado dos KPIs para mensurar o desempenho do
                  negócio. Esta etapa acontece durante a reunião mensal de
                  resultados.
                </p>
              </div>
              <div
                className={clsx([`col-lg-3 mb-2 mb-lg-0`, styles.stepsItem])}
              >
                <div className={styles.stepCircle}>
                  <FontAwesomeIcon icon={faExchangeAlt} />
                </div>
                <b>Plano de Açāo</b>
                <p>
                  Baseados na ferramenta 5W2H, são gerados nas reuniões mensais
                  de resultado e servem para melhorar os KPIs que estiverem
                  abaixo das metas estabelecidas. Nesta etapa acontece a
                  Mudança.
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row mt-5 align-items-center justify-content-center">
              <a
                href="https://valoremelhoria.com.br/#block8421"
                target="_blank"
                rel="noopener"
              >
                <Button>Conheça todos os nossos serviços</Button>
              </a>
            </div>
          </div>
        </div>

        <div className="container">
          <Divider />
        </div>

        <div className="py-5">
          <div className={clsx([`container`, styles.containerLarge])}>
            <div className="row align-items-center">
              <div className="col-lg-4 mb-3 mb-lg-0 pr-4">
                <h2 className="mb-3" style={{ color: '#1fa9e2' }}>
                  Metodologia Utilizada
                </h2>
                <p className="mb-3">
                  Conheça os 04 principais passos de nossa metodologia para
                  melhorar o resultado da sua organização.
                </p>

                <AnchorLink to={'/#contato'} className="hidden-mobile">
                  <Button>Dúvidas? Fale com a gente</Button>
                </AnchorLink>
              </div>
              <div className={clsx(['col-lg-8 pl-4', styles.boxMethodology])}>
                <ul className={styles.methodology}>
                  <li>
                    <span>
                      <small>#</small>1
                    </span>
                    <div>
                      <h3>Diagnóstico</h3>
                      <p>
                        Durante a fase de diagnóstico, iremos gerar um relatório
                        histórico com os principais indicadores que direcionam o
                        resultado do negócio. Entendemos a relação entre os
                        KPIs, construindo uma{' '}
                        <a
                          href="https://blog.valoremelhoria.com.br/2021-05-20-como-os-kpis-podem-melhorar-o-resultado-do-seu-neg%C3%B3cio/#indicadores"
                          target="_blank"
                          rel="noopener"
                        >
                          árvore de indicadores{' '}
                        </a>
                        desde o nível estratégico e tático ao operacional.{' '}
                        <a
                          href="https://blog.valoremelhoria.com.br/2021-06-28-boas-pr%C3%A1ticas-para-gest%C3%A3o-por-indicadores/"
                          target="_blank"
                          rel="noopener"
                        >
                          Assista ao nosso vídeo sobre boas práticas para gestão
                          por indicadores.
                        </a>
                      </p>
                    </div>
                  </li>
                  <li>
                    <span>
                      <small>#</small>2
                    </span>
                    <div>
                      <h3>Plano de Metas</h3>
                      <p>
                        Estabeleceremos um plano de metas SMART, com metas
                        específicas, mensuráveis, atingíveis, relevantes e
                        temporais. O plano de metas é elaborado com
                        Meritocracias ligadas aos donos dos KPIs. É importante
                        deixar clara a recompensa ao time com base no
                        cumprimento destas metas.
                      </p>
                    </div>
                  </li>
                  <li>
                    <span>
                      <small>#</small>3
                    </span>
                    <div>
                      <h3>Report Mensal</h3>
                      <p>
                        Fase em que ocorre o Processo Decisório embasado no
                        relatório mensal comparando-se o resultado previsto vs
                        realizado dos indicadores para mensurar o desempenho do
                        negócio. Os donos dos KPIs serão treinados para
                        realizare a análise, compilação e apresentação dos
                        resultados.
                      </p>
                    </div>
                  </li>
                  <li>
                    <span>
                      <small>#</small>4
                    </span>
                    <div>
                      <h3> Gestão da Mudança (GMO)</h3>
                      <p>
                        Como acontece a Gestão da Mudança Organizacional? Por
                        meio dos planos de ações gerados nas reuniões mensais de
                        resultado, que servem para melhorar os KPIs que
                        estiverem abaixo das metas. Utilizamos técnicas de GMO
                        dos instituto HCMI para engajar e "puxar" as mudanças
                        para que não fiquem apenas no papel.{' '}
                        <a
                          href="https://blog.valoremelhoria.com.br/2021-08-24-boas-pr%C3%A1ticas-para-a-gest%C3%A3o-da-mudan%C3%A7a/"
                          target="_blank"
                          rel="noopener"
                        >
                          Assista ao nosso vídeo sobre boas práticas para a
                          gestão da mudança.
                        </a>
                      </p>
                    </div>
                  </li>
                  <li className="pt-4 justify-content-center hidden-mobile-up">
                    <AnchorLink to={'/#contato'}>
                      <Button>Dúvidas? Fale com a gente</Button>
                    </AnchorLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SuccessCases />
      <AboutUs />
      <Statistics />
      <div className="container">
        <Divider />
      </div>

      <Offices />
      <Footer />
    </main>
  )
}

export default Home
