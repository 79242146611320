import React from 'react'
import clsx from 'clsx'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons'
import Slick from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import * as styles from '../styles/components/SuccessCases.module.scss'

import { Button } from './Button'
import { SectionTitle } from './SectionTitle'

const cases = [
  {
    title: `Diman Serviços Mecânicos`,
    text: `Implantação da rotina de gestão através da reunião mensal de análise
    de resultados. Realizadas mudanças na gestão através da classificação
    adequada de gastos, sistematização de relatórios e planejamento
    orçamentário.`,
  },
  {
    title: `TPC Law`,
    text: `Implantação da rotina de gestão para controles operacionais e
    tomada de decisões pautada em KPIs, possibilitando um crescimento
    seguro e sustentável.`,
  },
  {
    title: `Tecca Engenharia de Sistemas`,
    text: `Após o projeto foram fechados dois grandes negócios, resultado de uma
    maior assertividade na formação do preço de venda. Trabalho feito
    com foco nos custos diretos e créditos e débitos de impostos.`,
  },
]

export const SuccessCases: React.FC = () => {
  return (
    <section id="cases-sucesso" className={clsx([`my-5`, styles.cases])}>
      <div className="container">
        <SectionTitle
          title="Cases de Sucesso"
          description="Veja alguns de nossos cases de sucesso. Entre em contato e saiba mais sobre nossas melhorias que transformaram as organizações."
        />
        <div className={styles.mainCases}>
          <Slick
            className={styles.slick}
            adaptiveHeight={true}
            infinite={false}
            autoplay={false}
            slidesToShow={3}
            slidesToScroll={1}
            dots={false}
            draggable={false}
            speed={300}
            dotsClass={clsx([`slick-dots`, styles.customDots])}
            nextArrow={<FontAwesomeIcon icon={faChevronRight} />}
            prevArrow={<FontAwesomeIcon icon={faChevronLeft} />}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  dots: true,
                  arrows: false,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: true,
                  arrows: false,
                },
              },
            ]}
          >
            {cases.map((item, key) => (
              <div className={styles.caseBox} key={key}>
                <div className={styles.caseItem}>
                  <div className={styles.title}>
                    <h2>{item.title}</h2>
                    <p>{item.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slick>
        </div>
        <div className="container">
          <div className="row mt-5 pt-5 pt-lg-0 align-items-center justify-content-center">
            <a
              href="https://valoremelhoria.com.br/#b8673"
              target="_blank"
              rel="noopener"
            >
              <Button>Conheça todos os nossos clientes</Button>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
